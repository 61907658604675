<template>
  <div>
    <div class="d-flex flex-wrap justify-content-between mb-2">
      <div>
        <h3 class="my-auto">Liste des agents de police du commissariat</h3>
      </div>
      <div>
        <b-button
          v-b-modal.modal-center-1
          class="mt-1 float-right"
          variant="primary"
        >
          <feather-icon icon="PlusIcon" class="mr-25" />
          <span>Ajouter un agent de police</span>
        </b-button>
      </div>
    </div>

    <b-table
      responsive
      :items="tableData"
      :fields="tableColumns"
      show-empty
      empty-text="Aucun resultats"
      :busy="isLoadingTableData"
      hover
    >
      <template #table-busy>
        <div class="text-center my-2">
          <b-spinner class="align-middle" />
        </div>
      </template>
      <template #cell(id)="data">
        <strong>{{
          (paginationData.perPage * (paginationData.currentPage - 1) || 0) +
          data.index +
          1
        }}</strong>
      </template>
      <template #cell(ref)="data">
        <b-avatar variant="dark"> <feather-icon icon="ImageIcon" /> </b-avatar
        >{{ "  " }}
        {{ data.item.ref }}
      </template>
      <template #cell(nbr_fiche)="data" class="d-flex flex-row align-items-center">
        {{ data.item.fiches.length }}
      </template>
      <template #cell(actions)="data">
      <div class="d-flex flex-row justify-content-end gap-4">
        <ButtonArchiverPolicier
          :agent-police-id="data.item._id"
          :commissariat-id="commissariatId"
        />
        <b-button
            v-b-modal.modal-show-fiches
            variant="primary"
            class="px-2"
            v-b-tooltip.hover
            title="Afficher les fiches remplies"
          >
            <feather-icon icon="EyeIcon" />
          </b-button>
      </div>
      </template>
    </b-table>
    <div
      v-if="tableData && tableData.length > 1"
      class="d-flex justify-content-between mt-1"
    >
      <div class="d-flex flex-row">
        <span class="my-auto mr-1">Afficher </span>
        <div>
          <b-form-select
            v-model="paginationData.perPage"
            :options="optionsPerPage"
            size="sm"
            class="form-control col"
          />
        </div>
        <span class="my-auto ml-1">lignes </span>
      </div>

      <div>
        <b-pagination
          v-model="paginationData.currentPage"
          :total-rows="paginationData.total"
          :per-page="paginationData.perPage"
          first-number
          last-number
          class="mb-0 mt-1 mt-sm-0"
          prev-class="prev-item"
          next-class="next-item"
        >
          <template #prev-text>
            <feather-icon icon="ChevronLeftIcon" size="18" />
          </template>
          <template #next-text>
            <feather-icon icon="ChevronRightIcon" size="18" />
          </template>
        </b-pagination>
      </div>

      <div class="my-auto">
        <!-- <div class="d-flex flex-row">  -->
        <span class="my-auto"
          >Affichage de {{ paginationData.metaData.from }} à
          {{ paginationData.metaData.to }} sur {{ paginationData.total }}
        </span>
        <!-- </div> -->
      </div>
    </div>
    <!-- modal create -->
    <b-modal
      id="modal-center-1"
      cancel-variant="outline-secondary"
      ok-title="Ajouter"
      cancel-title="Fermer"
      centered
      title="Nouveau Agent de Police"
      @ok="saveNewAgent()"
    >
      <b-form>
        <!-- noms -->
        <b-form-group>
          <label for="email">Nom:</label>
          <b-form-input
            v-model="newAgentPolice.lastname"
            type="text"
            placeholder="ATTOU"
          />
          <div v-if="$v.newAgentPolice.lastname.$error">
            <small
              v-if="$v.newAgentPolice.lastname.required.$invalid"
              class="text-danger"
              >Ce champ est requis</small
            >
          </div>
        </b-form-group>
        <!-- prénoms -->
        <b-form-group>
          <label for="password">Prénom(s):</label>
          <b-form-input
            v-model="newAgentPolice.firstname"
            type="text"
            placeholder="Jean"
          />
          <div v-if="$v.newAgentPolice.firstname.$error">
            <small
              v-if="$v.newAgentPolice.firstname.required.$invalid"
              class="text-danger"
              >Ce champ est requis</small
            >
          </div>
        </b-form-group>
        <!-- email -->
        <b-form-group>
          <label for="email">Email:</label>
          <b-form-input
            v-model="newAgentPolice.email"
            type="email"
            placeholder="jean@gmail.com"
          />
          <div v-if="$v.newAgentPolice.email.$error">
            <small
              v-if="$v.newAgentPolice.email.emailValidator.$invalid"
              class="text-danger"
              >Adresse email invalide</small
            >
            <small
              v-if="$v.newAgentPolice.email.required.$invalid"
              class="text-danger"
              >Ce champ est requis</small
            >
          </div>
        </b-form-group>
        <!-- password -->
        <b-form-group
          label-for="register-password"
          label="Mot de passe"
          class="password-field"
        >
          <b-input-group
            class="input-group-merge"
            :class="$v.newAgentPolice.password.$error ? 'is-invalid' : null"
          >
            <b-form-input
              id="register-password"
              v-model="newAgentPolice.password"
              class="form-control-merge"
              :type="passwordFieldType"
              :state="$v.newAgentPolice.password.$error ? false : null"
              name="register-password"
              placeholder="············"
              @focus="passwordFieldIsFocused = true"
              @blur="
                passwordFieldIsFocused = false;
                $v.newAgentPolice.password.$touch;
              "
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="
                  passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
                "
                class="cursor-pointer"
                @click="togglePasswordInputType"
              />
            </b-input-group-append>
          </b-input-group>
          <div
            v-if="passwordFieldIsFocused || $v.newAgentPolice.password.$error"
            class="invalid-feedback password-rules"
          >
            <small
              v-if="
                passwordFieldIsFocused ||
                $v.newAgentPolice.password.isValid.$invalid
              "
            >
              <span class="label"> Le mot de passe doit contenir :</span>
              <ul class="list-style-none password-validators-errors">
                <li
                  :class="{
                    valid: passwordValidators(newAgentPolice.password)
                      .atLeastOneCapLetter,
                  }"
                >
                  <div class="icons">
                    <i
                      v-if="
                        !passwordValidators(newAgentPolice.password)
                          .atLeastOneCapLetter
                      "
                      class="las la-times-circle"
                    />
                    <i
                      v-if="
                        passwordValidators(newAgentPolice.password)
                          .atLeastOneCapLetter
                      "
                      class="las la-check-circle"
                    />
                  </div>
                  au moins une lettre majuscule
                </li>
                <li
                  :class="{
                    valid: passwordValidators(newAgentPolice.password)
                      .atLeastOneMinusLetter,
                  }"
                >
                  <div class="icons">
                    <i
                      v-if="
                        !passwordValidators(newAgentPolice.password)
                          .atLeastOneMinusLetter
                      "
                      class="las la-times-circle"
                    />
                    <i
                      v-if="
                        passwordValidators(newAgentPolice.password)
                          .atLeastOneMinusLetter
                      "
                      class="las la-check-circle"
                    />
                  </div>
                  au moins une lettre minuscule
                </li>
                <li
                  :class="{
                    valid: passwordValidators(newAgentPolice.password)
                      .atLeastOneDigits,
                  }"
                >
                  <div class="icons">
                    <i
                      v-if="
                        !passwordValidators(newAgentPolice.password)
                          .atLeastOneDigits
                      "
                      class="las la-times-circle"
                    />
                    <i
                      v-if="
                        passwordValidators(newAgentPolice.password)
                          .atLeastOneDigits
                      "
                      class="las la-check-circle"
                    />
                  </div>
                  au moins un chiffre
                </li>
                <li
                  :class="{
                    valid: passwordValidators(newAgentPolice.password)
                      .atLeastOneSpecialChar,
                  }"
                >
                  <div class="icons">
                    <i
                      v-if="
                        !passwordValidators(newAgentPolice.password)
                          .atLeastOneSpecialChar
                      "
                      class="las la-times-circle"
                    />
                    <i
                      v-if="
                        passwordValidators(newAgentPolice.password)
                          .atLeastOneSpecialChar
                      "
                      class="las la-check-circle"
                    />
                  </div>
                  au moins un caractère special (@#$%^&+=)
                </li>
                <li
                  :class="{
                    valid: passwordValidators(newAgentPolice.password)
                      .minimumLength,
                  }"
                >
                  <div class="icons">
                    <i
                      v-if="
                        !passwordValidators(newAgentPolice.password)
                          .minimumLength
                      "
                      class="las la-times-circle"
                    />
                    <i
                      v-if="
                        passwordValidators(newAgentPolice.password)
                          .minimumLength
                      "
                      class="las la-check-circle"
                    />
                  </div>
                  au moins 8 caractères
                </li>
              </ul>
            </small>
          </div>
        </b-form-group>
        <!--  confirm password -->
        <b-form-group
          label-for="register-password"
          label="Confirmer le mot de passe"
        >
          <b-input-group
            class="input-group-merge"
            :class="
              $v.newAgentPolice.confirmPassword.$error ? 'is-invalid' : null
            "
          >
            <b-form-input
              id="register-password"
              v-model="newAgentPolice.confirmPassword"
              class="form-control-merge"
              :type="passwordFieldType"
              :state="$v.newAgentPolice.confirmPassword.$error ? false : null"
              name="register-password"
              placeholder="············"
              @blur="$v.newAgentPolice.confirmPassword.$touch"
            />
            <b-input-group-append is-text>
              <feather-icon
                :icon="
                  passwordFieldType === 'password' ? 'EyeIcon' : 'EyeOffIcon'
                "
                class="cursor-pointer"
                @click="togglePasswordInputType"
              />
            </b-input-group-append>
          </b-input-group>
          <div v-if="$v.newAgentPolice.confirmPassword.$error">
            <small
              v-if="$v.newAgentPolice.confirmPassword.isValid.$invalid"
              class="text-danger"
              >Les mots de passe ne sont pas identiques</small
            >
          </div>
        </b-form-group>
      </b-form>
    </b-modal>
    <b-modal
      id="modal-show-fiches"
      ok-only
      ok-title="Fermer"
      title="Fiches remplies par l'agent"
      size="lg"
      centered
    >
      <b-table
        responsive
        :items="fichesRemplies"
        :fields="fichesTableColumns"
        show-empty
        empty-text="Aucun resultats"
        :busy="isLoadingfichesData"
        hover
      >
        <template #cell(actions)="data">
          <div>
            <b-button
              variant="primary"
              class="px-2 ml-auto"
              v-b-tooltip.hover
              title="Voir le détail de la fiche "
              :to="{
                name: 'formulaires-details',
                params: {
                id: data.item.id,
                model: data.item.key === 'formfrontiereentreevoyageur' ? 'forms-frontiere-entree-voyageurs':'forms-frontiere-sortie-voyageurs',
                },
              }"
              
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
          </div>
        </template>
      </b-table>    
    </b-modal>
  </div>
</template>

<script>
import {
  BFormCheckbox,
  BPagination,
  BAvatar,
  BButton,
  BTable,
  BSpinner,
  BFormSelect,
  BCard,
  BModal,
  VBTooltip,
  VBModal,
  BAlert,
  BRow,
  BForm,
  BFormGroup,
  BFormInput,
  BInputGroup,
  BInputGroupAppend,
} from "bootstrap-vue";
import Ripple from "vue-ripple-directive";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import ButtonArchiverPolicier from "@/views/apps/utilisateurs/commissariat/components/ButtonArchiverPolicier.vue";
import { mapGetters, mapActions } from "vuex";
import { required, email as emailValidator } from "@vuelidate/validators";
import passwordValidators from "@/helpers/password-validators";
import useVuelidate from "@vuelidate/core";

// store module and vuex utilities
import paramsStroreModule from "@/store/params";
import usersStoreModule from "@/store/users";
import rolesStroreModule from "@/store/roles";
import commissaireStroreModule from "@/store/commissaire";
import {
  registerStoreModule,
  unregisterStoreModule,
} from "@/helpers/vuex-utils";

export default {
  setup() {
    const requiredStoreModules = [
      { path: "commissaire", module: commissaireStroreModule },
      { path: "roles", module: rolesStroreModule },
      { path: "params", module: paramsStroreModule },
      { path: "users", module: usersStoreModule },
    ];
    // Register module
    registerStoreModule(requiredStoreModules);

    return {
      passwordValidators,
      requiredStoreModules,
      $v: useVuelidate({ $lazy: true }),
    };
  },
  name: "ListFormsComponent",
  components: {
    BFormCheckbox,
    BFormSelect,
    BAvatar,
    BPagination,
    BButton,
    BTable,
    BSpinner,
    BCard,
    VBTooltip,
    BModal,
    BAlert,
    BFormInput,
    BForm,
    BFormGroup,
    BRow,
    ButtonArchiverPolicier,
    BInputGroup,
    BInputGroupAppend,
  },
  directives: {
    "b-tooltip": VBTooltip,
    "b-modal": VBModal,
    Ripple,
  },
  props: { agentId: null, formType: null, commissariatId: null },
  data() {
    return {
      filters: { options: {} },
      currentPage: 1,
      perPage: 10,
      rows: 100,
      tableData: null,
      filteredTableData: [],
      paginationData: {
        currentPage: 1,
        total: 0,
        perPage: 10,
        metaData: { from: 0, to: 0 },
      },
      fichesRemplies:[],
      fichesTableColumns: [
        { key: "id", label: "Identifiants", sortable: true },
        { key: "key", label: "Types", sortable: true },
        {  key: 'actions',
          label: 'actions',
          sortable: true,
          class: 'text-center'
        },
      ],
      optionsPerPage: [10, 15, 30, 100, 200],
      tableColumns: [
        { key: "id", label: "#", sortable: true },
        { key: "firstname", label: "Nom", sortable: true },
        { key: "lastname", label: "Prénom", sortable: true },
        {
          key: "email",
          label: "Email",
          sortable: true,
        },
        // {
        //   key: 'etablissement',
        //   label: 'Etablissement',
        //   sortable: true,
        // },
        {
          key: 'nbr_fiche',
          label: 'Nombre de fiche remplies',
          sortable: true,
        },
        {  key: 'actions',
          label: 'actions',
          sortable: true,
          class: 'text-center'
        },
      ],
      isLoadingTableData: false,
      isLoadingfichesData: false,
      newAgentPolice: {
        firstname: "",
        lastname: "",
        email: "",
        role: "agent_police",
        commissariatAssociated: this.commissariatId,
        password: "",
        confirmPassword: "",
      },
      passwordFieldType: "password",
      passwordFieldIsFocused: false,
    };
  },
  beforeDestroy() {
    unregisterStoreModule(this.requiredStoreModules);
  },
  computed: {
    ...mapGetters("params", {
      typesRoles: "getter_typesRoles",
    }),
    totalRows() {
      return (this.tableData && this.tableData.length) || 0;
    },
    passwordToggleIcon() {
      return this.passwordFieldType === "password" ? "EyeIcon" : "EyeOffIcon";
    },
  },
  watch: {
    perPage: {
      immediate: true,
      handler(val, old) {
        if (this.tableData && this.tableData[val - 1]) {
          this.filteredTableData = this.customCloneDeep(
            this.tableData.slice(0, val - 1)
          );
        }
      },
    },
    tableData: {
      immediate: true,
      handler(val, old) {
        if (this.tableData) {
          this.filteredTableData = this.customCloneDeep(this.tableData);
        }
      },
    },
    passwordFieldIsFocused: {
      handler(val, old) {
        this.$v.newAgentPolice.password.$validate();
      },
    },
    // selectedCategorie: {
    //   immediate: true,
    //   async handler(val, old) {
    //     await this.action_fetchTypesRoles();
    //     this.getListUsers();
    //   },
    // },
    "paginationData.currentPage": {
      handler(val, old) {
        this.getListUsers();
      },
    },
    "paginationData.perPage": {
      handler(val, old) {
        this.getListUsers();
      },
    },
  },
  async mounted() {
    this.getListUsers();
  },
  validations() {
    return {
      newAgentPolice: {
        firstname: { required },
        lastname: { required },
        email: { required, emailValidator },
        password: {
          required,
          isValid(value) {
            return this.passwordValidators(value).isValid;
          },
        },
        confirmPassword: {
          isValid: (val) => val && val === this.newAgentPolice.password,
        },
      },
    };
  },
  methods: {
    ...mapActions("users", {
      action_fetchUsers: "fetchUsers",
      action_fetchAgentsPolice: "fetchAgentsPolice",
    }),
    ...mapActions("params", { action_fetchTypesRoles: "fetchTypesRoles" }),
    ...mapActions("roles", { fetchRoles: "fetchRoles" }),
    ...mapActions("commissaire", { createAgentPolice: "createAgentPolice" }),

    getListUsers() {
      this.isLoadingTableData = true;
      this.isLoadingfichesData = false;
      const params = {
        options: {
          commissariatAssociated: this.commissariatId,
        },
        page: this.paginationData.currentPage - 1,
        perPage: this.paginationData.perPage,
      };
      console.log('params::: ', params);
      this.action_fetchAgentsPolice(params)
        .then((response) => {
          this.isLoadingTableData = false;
          this.isLoadingfichesData = false;
          this.tableData = response.data.resources || [];
          this.tableData = this.tableData.filter(e => {
            e.fiches = e.fiches.filter(fiche => fiche.key != null);
            this.fichesRemplies = e.fiches;
            return e.fiches.length > 0;
      })
          console.log('this.tableData::: ', this.tableData);
          console.log('this.ficchhes::: ', this.fichesRemplies);
          
          
          // boucle sur chaque ligne, faire une accumulation du nombre de fiche remplie en prenant en compte uniquement 
          // les fiches qui ont l'attribut key != null 


          // ----------------------------------------------------
          this.paginationData.total = response.data.total || 0;
          if (this.paginationData.metaData.from == 0)
            this.paginationData.metaData.from += 1;
          else
            this.paginationData.metaData.from =
              this.paginationData.perPage * this.paginationData.currentPage -
              this.paginationData.perPage;
          this.paginationData.metaData.to =
            this.paginationData.perPage * this.paginationData.currentPage -
            this.paginationData.perPage +
            this.tableData.length;
        })
        .catch((err) => {
          this.isLoadingTableData = false;
        });
    },
    async saveNewAgent() {
      // Execute reCAPTCHA with action "submit".
      const token = await this.$recaptcha("submit");
      if (!token) {
        this.isLoading = false;
        return;
      }
      this.newAgentPolice.token = token;

      delete this.newAgentPolice.confirmPassword;
      // console.log('this.newAgentPolice::: 🔴', this.newAgentPolice)
      // return

      this.createAgentPolice(this.newAgentPolice)
        .then((res) => {
          if (res.status == 200) {
            this.$toast({
              component: ToastificationContent,
              position: "top-right",
              props: {
                title: "Ajout agent réussie",
                icon: "CheckIcon",
                variant: "success",
              },
            });
            this.getListUsers();
          }
        })
        .catch((err) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: err.message || "Une erreur est survenue",
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        });
    },
    togglePasswordInputType() {
      this.passwordFieldType =
        this.passwordFieldType === "password" ? "text" : "password";
    },
  },
};
</script>
<style lang="scss" scoped>
.link-wrapper {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 10px;
  a {
    text-transform: uppercase;
  }
}
.footer,
.footer .per-page {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
